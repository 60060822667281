<template>
  <page-layout
    :title="$t('products_management.products.title')"
    fixed-foter
  >
    <template #header-actions>
      <base-button
        type="primary"
        prepend-icon="ri-add-line"
        :label="$t('products_management.products.add_product_button')"
        @click.stop="$router.push({name:'ProductAdd'})"
      ></base-button>
    </template>
    <div class="filters">
      <el-form inline>
        <base-input
          class="search"
          v-model='searchInput'
          placeholder="Search"
        >
          <template #prependInput>
            <i class="ri-search-line"></i>
          </template>
        </base-input>
        <base-checkbox
        :label="$t('products_management.checkbox_inactive_label')"
        v-model="showInactive"/>
      </el-form>
    </div>
    <el-table
      ref="table"
      :key="key"
      :data.sync='tableData'
      :empty-text="$t('general.empty_table')"
      :default-sort="pagination.sort"
      row-class-name="table-row clickable"
      @row-click="rowClick"
      @sort-change='sortChange'
    >
      <el-table-column
        prop='extId'
        :label="$t('partners_management.view_partner.details.account_section.input_label.ext_id')"
        sortable='custom'
        :sort-orders="sortOrders"
      >
      </el-table-column>
      <el-table-column
        prop='textLabel'
        sortable='custom'
        :sort-orders="sortOrders"
        :label="$t('general.label.label')"
      >
        <template slot-scope={row}>
          {{ row.label }}
        </template>
      </el-table-column>
      <el-table-column
        :width="110"
        fixed='right'
      >
        <template slot-scope={row}>
          <base-button
            v-if='!row.active'
            size="small"
            type='success'
            :label="$t('general.button_label.activate')"
            @click.stop="handleActivate(row)"
          />
          <base-button
            v-else
            type='danger'
            @click.stop="handleDeactivate(row)"
            size="small"
            :label="$t('general.button_label.deactivate')"
          />
        </template>
      </el-table-column>
    </el-table>

    <template #footer v-if="totalPages > 1">
      <base-pagination
        v-model="pagination.page"
        :pages="totalPages"
      />
    </template>
  </page-layout>
</template>

<script>
import PageLayout from '../../../layouts/PageLayout'
import TableLoader from '@/mixins/TableLoader'

import { orderBy } from 'lodash'
import api from '@/api'
import Product from '@/app/models/Product'

export default {
  name: 'ProductViewAll',
  components: { PageLayout },
  mixins: [TableLoader],
  data () {
    return {
      key: null,
      showInactive: false,
      sortOrders: ['ascending', 'descending'],
      products: [],
      defaultSort: {
        order: 'ascending',
        prop: 'extId'
      },
      searchInput: '',
      filter: '',
      pagination: {
        size: 15,
        page: 0
      },
      sort: {
        prop: 'extId',
        order: 'ascending'
      }
    }
  },
  computed: {
    tableData () {
      const prop = this.sort.prop
      const order = this.sort.order === 'descending' ? 'desc' : 'asc'
      const { page, size } = this.pagination
      return orderBy(this.filteredProductsData,
        v => {
          try {
            return v[prop].toLowerCase()
          } catch (e) {
            return v[prop]
          }
        },
        order).slice(size * page, size * (page + 1))
    },
    filteredProductsData () {
      return this.products.filter(product => (product.test(this.searchInput) && product.active === !this.showInactive))
    },
    totalPages () {
      try {
        return Math.ceil(this.filteredProductsData.length / this.pagination.size)
      } catch (e) {
        return 1
      }
    }
  },
  methods: {
    handleActivate (product) {
      this.TableLoader_show()
      return this.$api.product.activate({ ospId: product.ospId }, {
        successNotification: this.$t('products_management.products.success_notification_activated', { extId: product.extId }),
        onSuccess: () => {
          this.TableLoader_hide()
          this.fetchData()
        },
        onError: () => {
          this.TableLoader_hide()
        }
      })
    },
    handleDeactivate (product) {
      this.TableLoader_show()
      return this.$api.product.deactivate({ ospId: product.ospId }, {
        successNotification: this.$t('products_management.products.success_notification_deactivated', { extId: product.extId }),
        onSuccess: () => {
          this.TableLoader_hide()
          this.fetchData()
        },
        onError: () => {
          this.TableLoader_hide()
        }
      })
    },
    fetchData () {
      this.TableLoader_show()
      return api.product.findByFilter({ active: null }, {
        onSuccess: res => {
          this.products = res.map(v => new Product(v))
        },
        onFinally: () => {
          this.TableLoader_hide()
        }
      })
    },
    sortChange (sort) {
      this.sort = sort
    },
    rowClick (product) {
      this.$router.push({
        name: 'ProductViewDetails',
        params: {
          productOspId: product.ospId
        }
      })
    }
  },
  created () {
    this.fetchData()
  }
}
</script>
