<template>
  <page-layout
    :title="product.extId"
    :sub-title="subtitle"
    :has-back-button="true"
    :emit-back="true"
    @back="back"
  >
    <template #header-actions>
      <template v-if="showCompositionButtons">
        <base-button
          :key="key + 'addResourceGroup'"
          :label="$t('products_management.view_product.button.add_resource_group')"
          @click="$refs.routerView.addResourceGroup"
        />
        <base-button
          :key="key + 'addServiceResource'"
          :label="$t('products_management.view_product.button.add_service_resource')"
          @click="$refs.routerView.addServiceResource"
        />
        <base-button
          :key="key + 'addRentalCarResource'"
          :label="$t('products_management.view_product.button.add_rental_car_resource')"
          @click="$refs.routerView.addRentalCarResource"
        />
      </template>
      <base-button
        v-if="showSaveDetailsButton"
        :key="key"
        :label="$t('general.button_label.save_changes')"
        type="primary"
        :loading="$refs.routerView.saving"
        :disabled="!$refs.routerView.dirty"
        @click="$refs.routerView.handleSaveChanges"
      />
    </template>
    <el-tabs :value="activeName" ref="tabs">
      <el-tab-pane v-for="tab in tabs" :key="tab.name" :name="tab.name">
        <span slot="label" class="flex-span"><i v-if="tab.icon" :class="tab.icon"></i> {{ tab.label }}</span>
      </el-tab-pane>
    </el-tabs>
    <router-view ref="routerView" :key="$route.name" :totalPages.sync="totalPages" :pagination.sync="pagination"/>
    <template #footer v-if="totalPages && totalPages > 1">
      <base-pagination
        :key="'pagination_' + $route.name"
        v-model="pagination.page"
        :pages="totalPages"
      />
    </template>
  </page-layout>
</template>

<script>
import PageLayout from '../layouts/PageLayout'
import { mapGetters } from 'vuex'
import TabNavigation from '../mixins/TabNavigation'
import ViewProduct from '@/mixins/ViewProduct'
import { get } from 'lodash'

export default {
  name: 'ProductViewLayout',
  components: {
    PageLayout
  },
  mixins: [TabNavigation, ViewProduct],
  props: {
    productOspId: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      key: '',
      activeName: '',
      showSaveDetailsButton: false,
      showCompositionButtons: false,
      totalPages: 0,
      pagination: {
        page: 0,
        size: 15,
        sort: {}
      }
    }
  },
  methods: {
    showButtons () {
      this.showSaveDetailsButton = !!this.$route.meta.showSaveButton
      this.showCompositionButtons = this.$route.name === 'ProductViewComposition'
    },
    back () {
      if (this.$refs.routerView && this.$refs.routerView.goBack) {
        return this.$refs.routerView.goBack()
      } else {
        this.$router.push({ name: 'ProductViewAll' })
      }
    }
  },
  watch: {
    '$route.name' () {
      this.$sleep(50).then(() => {
        this.key = this.$key()
        this.totalPages = 0
      })
    }
  },
  computed: {
    ...mapGetters({}),
    title () {
      return ''
    },
    tabs () {
      return [
        {
          label: this.$t('products_management.view_product.tabs.view_details'),
          name: 'ProductViewDetails',
          icon: 'ri-building-4-line'
        },
        {
          label: this.$t('products_management.view_product.tabs.view_composition'),
          name: 'ProductViewComposition',
          icon: 'ri-building-4-line'
        }
      ]
    },
    subtitle () {
      const currentTab = this.tabs.find(v => v.name === this.$route.name)
      return get(currentTab, 'label', '')
    }
  }
}
</script>
