import { get } from 'lodash'
import store from '@/store'
import { mapGetters } from 'vuex'
import ConfirmBeforeRouterLeave from '@/mixins/ConfirmBeforeRouterLeave'

export default {
  name: 'ViewProduct',
  mixins: [ConfirmBeforeRouterLeave],
  props: ['productOspId'],
  computed: {
    ...mapGetters({
      $_getByOspId: 'product/getByOspId'
    }),
    product () {
      return this.productOspId && this.$_getByOspId(this.productOspId)
    }
  },
  methods: {
    async goBack () {
      if (await this.allowRouterLeave()) {
        return this.$router.push({ name: 'ProductViewAll' })
      }
    }
  },
  async beforeRouteEnter (to, from, next) {
    const productOspId = get(to.params, 'productOspId', '')
    if (productOspId && !store.getters['product/getByOspId'](productOspId)) {
      try {
        await store.dispatch('product/findAllProducts')
      } catch (e) {
        console.error(e)
        return next(from)
      }
      if (!store.getters['product/getByOspId'](productOspId)) {
        return next(from)
      }
    }
    next()
  }
}
