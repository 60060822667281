<template>
  <page-layout
    :title="$t('products_management.add_products.title')"
    :has-back-button="true"
    :emit-back="true"
    @back="goBack"
  >
    <template #header-actions>
      <base-button
        :loading='loading'
        type="primary"
        :label="$t('products_management.add_products.create_button')"
        @click.stop="createProduct"
      ></base-button>
    </template>

    <base-section :title="$t('products_management.add_products.section_title')">
      <el-form style="max-width: 400px">
        <base-input
          required
          :label="$t('partners_management.view_partner.details.account_section.input_label.ext_id')"
          v-model="form.extId"
          :validations="this.$v.form.extId"
        ></base-input>
        <base-input
          :label="$t('general.label.default_label')"
          required
          v-model="form.defaultLabel"
          :validations="this.$v.form.defaultLabel">
        </base-input>
        <base-translations
          label='Translations'
          v-model='form.translations'>
        </base-translations>
      </el-form>
    </base-section>
  </page-layout>
</template>

<script>
import PageLayout from '../../../layouts/PageLayout'
import { required } from 'vuelidate/lib/validators'
import ConfirmBeforeRouterLeave from '@/mixins/ConfirmBeforeRouterLeave'

export default {
  name: 'ProductAdd',
  components: {
    PageLayout
  },
  mixins: [ConfirmBeforeRouterLeave],
  validations: {
    form: {
      defaultLabel: {
        required
      },
      extId: {
        required
      }
    }
  },
  data () {
    return {
      form: {
        defaultLabel: null,
        extId: null,
        translations: []
      },
      dirty: false,
      loading: false
    }
  },
  methods: {
    createProduct () {
      this.$v.$touch()
      if (this.$v.$invalid) {
        return false
      }
      this.loading = true
      this.$api.product.create(this.form, {
        successNotification: this.$t('products_management.add_products.success_notifications'),
        onSuccess: (productDetails) => {
          this.dirty = false
          return this.$router.push({
            name: 'ProductViewComposition',
            params: {
              productOspId: productDetails.ospId
            }
          })
        },
        onFinally: () => {
          this.loading = false
        }
      })
    }
  },
  mounted () {
    this.$nextTick().then(() => {
      this.dirty = false
      this.$watch('form', {
        deep: true,
        handler () {
          this.dirty = true
        }
      })
    })
  }
}
</script>
