<template>
  <el-dialog
    :title="$t('products_management.view_product.add_resource_dialog.title')"
    :visible.sync="dialogFormVisible"
    :close-on-click-modal="false"
    class="add-resource-dialog"
    :destroy-on-close="true"
    top="3vh"
    @close="close">
    <el-form
      :key="dialogKey"
      label-position='top'
    >
      <base-section :title="$t('products_management.view_product.add_resource_dialog.section_general.title')">
        <el-row :gutter="24">
          <el-col :span="12">
            <base-select
              :label="$t('reconciliation.label.currency')"
              ref="currency"
              prop="currency"
              :items="currenciesList"
              v-model="resource.currency"
              name="currency"
            />
          </el-col>
          <el-col :span="12">
            <base-input
              v-model="resource.feeCronNotation"
              name="feeCronNotation"
              auto-complete="off"
              :label="$t('products_management.view_product.add_resource_dialog.fee_cron_notation')"
            ></base-input>
          </el-col>
        </el-row>
        <el-row :gutter="24">
          <el-col :span="12">
            <base-datepicker
              ref="validFrom"
              prop="validFrom"
              name="validFrom"
              v-model="resource.validFrom"
              clearable
            ></base-datepicker>
          </el-col>
          <el-col :span="12">
            <base-datepicker
              ref="validTo"
              prop="validTo"
              name="validTo"
              v-model="resource.validTo"
              clearable
            ></base-datepicker>
          </el-col>
        </el-row>
        <el-row :gutter="24">
          <el-col :span="12">
            <base-currency-input
              v-model="resource.variableAmount"
              name="variableAmount"
              prop="variableAmount"
              auto-complete="off"
              :currency="selectedCurrency"
              :label="$t('products_management.view_product.add_resource_dialog.var_amount')"
            ></base-currency-input>
          </el-col>
          <el-col :span="12">
            <base-input
              v-model="resource.variableProRate"
              type="number"
              name="variableProRate"
              prop="variableProRate"
              auto-complete="off"
              :label="$t('products_management.view_product.add_resource_dialog.var_pro_rate')"
            ></base-input>
          </el-col>
        </el-row>
        <el-row :gutter="24">
          <el-col :span="12">
            <base-currency-input
              v-model="resource.fixedAmount"
              name="fixedAmount"
              prop="fixedAmount"
              auto-complete="off"
              :label="$t('products_management.view_product.add_resource_dialog.fix_amount')"
              :currency="selectedCurrency"
            ></base-currency-input>
          </el-col>
          <el-col :span="12">
            <base-input
              v-model="resource.vatValue"
              type="number"
              name="vatValue"
              prop="vatValue"
              auto-complete="off"
              :label="$t('products_management.view_product.add_resource_dialog.vat_percent')"
            ></base-input>
          </el-col>
        </el-row>
      </base-section>
      <base-section :title="$t('products_management.view_product.add_resource_dialog.section_fixed_cost.title')">
        <el-row :gutter="24">
          <el-col :span="12">
            <base-currency-input
              v-model="resource.fixedCost"
              name="fixedCost"
              prop="fixedCost"
              auto-complete="off"
              :label="$t('products_management.view_product.composition.table.cost')"
              :currency="selectedCurrency"
            ></base-currency-input>
          </el-col>
          <el-col :span="12">
            <base-input
              v-model="resource.fixedCostLabel.defaultValue"
              name="fixedDefaultValue"
              prop="fixedDefaultValue"
              auto-complete="off"
              :label="$t('general.label.default_label')"
            ></base-input>
          </el-col>
        </el-row>
        <el-row :gutter="24">
          <el-col :span="12">
            <base-checkbox
              :label="$t('products_management.view_product.add_resource_dialog.on_user_budget')"
              v-model="resource.fixedCostForUser"
            ></base-checkbox>
          </el-col>
          <el-col :span="12">
            <base-translations
              :label="$t('general.label.translations')"
              v-model="resource.fixedCostLabel.labelTranslations"
            ></base-translations>
          </el-col>
        </el-row>
      </base-section>
      <!--      <p>{{$t('products_management.view_product.add_resource_dialog.section_fixed_cost.title')}}</p>-->
      <base-section :title="$t('products_management.view_product.add_resource_dialog.section_var_cost.title')">
        <el-row :gutter="24">
          <el-col :span="12">

            <base-input
              v-model="resource.variableCost"
              name="fixedCost"
              prop="fixedCost"
              auto-complete="off"
              :label="$t('products_management.view_product.composition.table.cost')"
              v-mask="['#%', '##%']"
            >
            </base-input>
<!--            <base-currency-input-->
<!--              v-model="resource.variableCost"-->
<!--              type="number"-->
<!--              name="fixedCost"-->
<!--              prop="fixedCost"-->
<!--              auto-complete="off"-->
<!--              :label="$t('products_management.view_product.composition.table.cost')"-->
<!--              :currency="selectedCurrency"-->
<!--            ></base-currency-input>-->
          </el-col>
          <el-col :span="12">
            <base-input
              v-model="resource.variableCostLabel.defaultValue"
              name="fixedDefaultValue"
              prop="fixedDefaultValue"
              auto-complete="off"
              :label="$t('general.label.default_label')"
            ></base-input>
          </el-col>
        </el-row>
        <el-row :gutter="24">
          <el-col :span="12">
            <base-checkbox
              :label="$t('products_management.view_product.add_resource_dialog.on_user_budget')"
              v-model="resource.variableCostForUser"
            ></base-checkbox>
          </el-col>
          <el-col :span="12">
            <base-translations
              :label="$t('general.label.translations')"
              v-model="resource.variableCostLabel.labelTranslations"
            ></base-translations>
          </el-col>
        </el-row>
      </base-section>
      <!--      <p>{{$t('products_management.view_product.add_resource_dialog.section_var_cost.title')}}</p>-->
    </el-form>
    <template slot="footer" class="dialog-footer">
      <base-button
        :label="$t('general.cancel')"
        @click="close"
      ></base-button>
      <base-button
        :loading="loading"
        type="primary"
        :label="$t('general.button_label.save_changes')"
        @click="handleConfirm"
      ></base-button>
    </template>
  </el-dialog>
</template>

<script>
import TableLoader from '@/mixins/TableLoader'
import CancelRequest from '@/mixins/CancelRequest'
import ProductCompositionResource from '@/app/models/ProductCompositionResource'

export default {
  name: 'AddResourceDialog',
  mixins: [TableLoader, CancelRequest],
  data () {
    return {
      edit: null,
      loading: false,
      search: '',
      dialogKey: '',
      dialogFormVisible: false,
      productOspId: null,
      resource: new ProductCompositionResource(),
      currenciesList: [
        {
          value: 'EUR',
          label: 'EUR'
        },
        {
          value: 'USD',
          label: 'USD'
        }
      ]
    }
  },
  computed: {
    selectedCurrency () {
      const sign = this.resource.currency === 'USD' ? '$' : '€'
      return {
        prefix: `${sign} `
      }
    }
  },
  methods: {
    show ({ resource, productOspId, edit = false }) {
      this.resource = resource
      this.productOspId = productOspId
      this.edit = edit
      this.dialogKey = this.$key()
      this.dialogFormVisible = true
    },
    close () {
      this.dialogFormVisible = false
      this.order = null
      // this.resource = {}
    },
    handleConfirm () {
      // this.$v.form.$touch()
      // if (this.$v.form.$invalid) {
      //   return true
      // }
      this.loading = true
      const options = {
        onSuccess: () => {
          this.$emit('success')
          this.close()
        },
        onFinally: () => { this.loading = false }
      }
      if (this.edit) {
        return this.resource.update(this.productOspId, {
          successNotification: this.$t('products_management.view_product.add_resource_dialog.success_update'),
          ...options
        })
      }
      return this.resource.addToProduct(this.productOspId, {
        successNotification: this.$t('products_management.view_product.add_resource_dialog.success_add'),
        ...options
      })
    }
  }
}
</script>
