<template>
  <el-form v-loading="loading">
    <el-table
      ref="table"
      :data.sync="tableData"
      :empty-text="$t('general.empty_table')"
      :default-sort="pagination.sort"
      row-class-name="table-row"
      @sort-change="sortChange"
      style="width: 100%"
    >
      <el-table-column
        prop="ospId"
        :label="$t('products_management.view_product.composition.table.context_ospid')"
        min-width="220"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="cost"
        :label="$t('products_management.view_product.composition.table.cost')"
        width="110"
      >
        <template slot-scope="{row}">
          {{ $currency(row.cost) }}
        </template>
      </el-table-column>
      <el-table-column
        prop="variable"
        :label="$t('products_management.view_product.composition.table.var')"
        width="50">
        <template slot-scope="{row}">
          <i class="activity-status" :class="row.variable ? 'active ri-check-line' : 'inactive ri-close-line'">
          </i>
        </template>
      </el-table-column>
      <el-table-column
        prop="vatValue"
        :label="$t('products_management.view_product.composition.table.vat')"
        width="80"
      ></el-table-column>
      <el-table-column
        prop="validFrom"
        :label="$t('user_management.section.trip_registration.table_header.valid_form')"
        width="150"
      >
        <template slot-scope="{row}">
          {{
            row.validFrom ? $dateFormat(row.validFrom, DATE_TIME_FORMAT) : $t('products_management.view_product.composition.table.not_specified')
          }}
        </template>
      </el-table-column>
      <el-table-column
        prop="validTo"
        :label="'Valid To'"
        width="150"
      >
        <template slot-scope="{row}">
          {{
            row.validTo ? $dateFormat(row.validTo, DATE_TIME_FORMAT) : $t('products_management.view_product.composition.table.not_specified')
          }}
        </template>
      </el-table-column>
      <el-table-column
        fixed="right"
        class-name="actions-cell"
        width="140">
        <template slot-scope="{row}">
          <base-button
            @click.stop="() => handleDelete(row)"
            size="small"
            prepend-icon="ri-delete-bin-2-line"
          >
          </base-button>
          <base-button
            @click.stop="() => handleEdit(row)"
            size="small"
            prepend-icon="ri-pencil-line"
            :label="$t('general.button_label.edit')"
          />
        </template>
      </el-table-column>
    </el-table>
    <add-resource-dialog
      ref="editResourceGroupDialog"
      @success="fetchData"
    ></add-resource-dialog>
  </el-form>
</template>
<script>
import ViewProduct from '@/mixins/ViewProduct'
import TableLoader from '@/mixins/TableLoader'
import CancelRequest from '@/mixins/CancelRequest'
import { orderBy } from 'lodash'
import { DATE_TIME_FORMAT } from '@/app/helpers'
import { DateTime } from 'luxon'
import ProductCompositionResource from '@/app/models/ProductCompositionResource'
import AddResourceDialog from '@/components/Backoffice/Products/AddResourceDialog'

const defaultSort = { order: 'ascending', prop: 'ospId' }
export default {
  name: 'ProductViewComposition',
  components: {
    AddResourceDialog
  },
  mixins: [ViewProduct, TableLoader, CancelRequest],
  props: ['totalPages', 'pagination'],
  data () {
    return {
      DATE_TIME_FORMAT,
      form: {},
      key: '',
      saving: false,
      loading: false,
      dirty: false,
      dirtyTableData: []
    }
  },
  watch: {
    totalPages (v) {
      const pagination = { ...this.pagination }
      if (v && pagination.page >= v) {
        pagination.page = v - 1
        this.$emit('update:pagination', pagination)
      }
    }
  },
  computed: {
    tableData () {
      const prop = this.pagination.sort.prop
      const order = this.pagination.sort.order === 'descending' ? 'desc' : 'asc'
      return orderBy(this.dirtyTableData, v => {
        try {
          return v[prop].toLowerCase()
        } catch (e) {
          return v[prop]
        }
      }, order)
        .slice(this.pagination.page * this.pagination.size, (this.pagination.page + 1) * this.pagination.size)
        .map(resource => new ProductCompositionResource(resource))
    }
  },
  methods: {
    fetchData () {
      this.TableLoader_show()
      this.$api.product.getComposition(this.productOspId, {
        onSuccess: ({ productCompositionDetails }) => {
          this.dirtyTableData = productCompositionDetails
            .filter(resource => !resource.validTo || DateTime.local() < DateTime.fromISO(resource.validTo))
          let totalPages = 1
          try {
            totalPages = Math.ceil(this.dirtyTableData.length / this.pagination.size)
          } catch (e) {
            totalPages = 1
          }
          this.$emit('update:totalPages', totalPages)
        },
        onFinally: this.TableLoader_hide
      })
    },
    sortChange (sort) {
      this.pagination.sort = sort
    },
    addResourceGroup () {
      this.$router.push({
        name: 'ProductViewAddResourceGroup'
      })
    },
    addServiceResource () {
      this.$router.push({
        name: 'ProductViewAddServiceResource'
      })
    },
    addRentalCarResource () {
      this.$router.push({
        name: 'ProductViewAddRentalCarResource'
      })
    },
    handleEdit ({ ospId }) {
      this.TableLoader_show()
      this.$api.product.getCompositionDetails({
        detailsOspId: ospId,
        productOspId: this.productOspId
      }, {
        onSuccess: response => {
          const resource = new ProductCompositionResource(response)
          // console.log(response)
          this.$refs.editResourceGroupDialog.show({
            resource,
            productOspId: this.productOspId,
            edit: true
          })
        },
        onFinally: this.TableLoader_hide
      })
    },
    handleDelete (resource) {
      this.TableLoader_show()
      return resource.deleteFromProduct(this.productOspId, {
        onSuccess: () => {
          return this.fetchData()
        },
        onError: this.TableLoader_hide
      })
    }
  },
  mounted () {
    this.$emit('update:pagination', {
      page: 0,
      size: 15,
      sort: { ...defaultSort }
    })
    this.$nextTick(() => {
      this.fetchData()
    })
  }
}
</script>
