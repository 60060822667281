<template>
  <page-layout
    :title="$t('products_management.view_product.add_service_resource')"
    :sub-title="''"
    :has-back-button="true"
  >
    <el-form
      :key="key"
      label-position='top'
    >
      <base-input
        class="search"
        v-model='search'
        :placeholder="$t('general.search')"
      >
        <template #prependInput>
          <i class="ri-search-line"></i>
        </template>
      </base-input>
    </el-form>
    <el-table
      ref="table"
      :data.sync="tableData"
      :empty-text="$t('general.empty_table')"
      :default-sort="sort"
      row-class-name="table-row"
      @sort-change="sortChange"
      style="width: 100%"
    >
      <el-table-column
        prop="mrpOspId"
        :label="$t('general.label.mrp_osp_id')"
        min-width="200"
        show-overflow-tooltip
        sortable="customSort"
      ></el-table-column>
      <el-table-column
        prop="name"
        :label="$t('general.label.name')"
        min-width="200"
        show-overflow-tooltip
        sortable="customSort"
      >
      </el-table-column>
      <el-table-column
        prop="mobilityMode"
        :label="$t('general.label.mobility_mode')"
        min-width="200"
        show-overflow-tooltip
        sortable="customSort"
      >
      </el-table-column>
      <el-table-column
        fixed="right"
        class-name="actions-cell"
        width="100">
        <template slot-scope="{row}">
          <base-button
            @click.stop="() => add(row)"
            size="small"
            prepend-icon="ri-add-line"
            :label="$t('general.label.add')"
          />
        </template>
      </el-table-column>
    </el-table>
    <add-resource-dialog
      ref="addResourceGroupDialog"
    ></add-resource-dialog>
    <template #footer v-if="totalPages && totalPages > 1">
      <base-pagination
        v-model="page"
        :pages="totalPages"
      />
    </template>
  </page-layout>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import PageLayout from '@/layouts/PageLayout'
import AddResourceDialog from '@/components/Backoffice/Products/AddResourceDialog'
import ProductAddResource from '@/mixins/ProductAddResource'

export default {
  name: 'ProductViewAddServiceResource',
  components: {
    PageLayout,
    AddResourceDialog
  },
  mixins: [ProductAddResource],
  data () {
    return {
      sort: { order: 'ascending', prop: 'mrpOspId' }
    }
  },
  computed: {
    ...mapGetters({
      dirtyData: 'product/flatServiceResources'
    })
  },
  methods: {
    ...mapActions({
      getAllServiceResources: 'product/getAllServiceResources'
    }),
    fetchData () {
      this.TableLoader_show()
      return this.getAllServiceResources({
        onFinally: this.TableLoader_hide
      })
    },
    filterData ({ ospId, name, mobilityMode, mrpOspId }) {
      return ospId.toLowerCase().includes(this.search.toLowerCase()) ||
        name.toLowerCase().includes(this.search.toLowerCase()) ||
        mobilityMode.toLowerCase().includes(this.search.toLowerCase()) ||
        mrpOspId.toLowerCase().includes(this.search.toLowerCase())
    }
  }
}
</script>
