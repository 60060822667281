<template>
  <div
    v-loading="loading"
  >
    <!--    The colour manual can be found here.-->
    <base-section :title="$t('products_management.add_products.section_title')">
      <el-form style="max-width: 400px">
        <base-input
          required
          :label="$t('partners_management.view_partner.details.account_section.input_label.ext_id')"
          disabled
          v-model="product.extId"
        ></base-input>
        <base-input
          :label="$t('general.label.default_label')"
          required
          v-model="form.productLabel.defaultValue"
          :validations="this.$v.form.productLabel.defaultValue">
        </base-input>
        <base-translations
          :label="$t('general.label.translations')"
          v-model='form.productLabel.labelTranslations'>
        </base-translations>
      </el-form>
    </base-section>
  </div>
</template>
<script>
import ViewProduct from '@/mixins/ViewProduct'
import { required } from 'vuelidate/lib/validators'

export default {
  name: 'ProductViewDetails',
  mixins: [ViewProduct],
  data () {
    return {
      form: {
        productLabel: {
          defaultValue: null,
          labelTranslations: []
        }
      },
      key: '',
      saving: false,
      loading: false,
      dirty: false
    }
  },
  validations: {
    form: {
      productLabel: {
        defaultValue: {
          required
        }
      }
    }
  },
  computed: {},
  methods: {
    handleSaveChanges () {
      console.log('handleSaveChanges')
      this.$v.$touch()
      if (this.$v.$invalid) {
        return false
      }
      this.loading = true
      this.$api.product.update(this.form, {
        successNotification: this.$t('products_management.view_product.details.success_notification'),
        onSuccess: () => {
          this.dirty = false
          // return this.$router.back()
        },
        onFinally: () => {
          this.loading = false
        }
      })
    }
  },
  mounted () {
    this.$nextTick().then(() => {
      this.dirty = false
      this.$watch('form', {
        deep: true,
        handler () {
          this.dirty = true
        }
      })
    })
  },
  async beforeMount () {
    this.form = this.product.getUpdateForm()
  }
}
</script>
