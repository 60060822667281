<template>
  <page-layout
    :title="title"
    :sub-title="''"
    :has-back-button="true"
  >
    <el-form
      :key="key"
      label-position='top'
    >
      <base-input
        class="search"
        v-model='search'
        :placeholder="$t('general.search')"
      >
        <template #prependInput>
          <i class="ri-search-line"></i>
        </template>
      </base-input>
    </el-form>
    <el-table
      ref="table"
      :data.sync="tableData"
      :empty-text="$t('general.empty_table')"
      :default-sort="sort"
      row-class-name="table-row"
      @sort-change="sortChange"
      style="width: 100%"
    >
      <el-table-column
        prop="ospId"
        :label="$t('general.label.osp_id')"
        min-width="200"
        show-overflow-tooltip
        sortable="customSort"
      ></el-table-column>
      <el-table-column
        fixed="right"
        class-name="actions-cell"
        width="100">
        <template slot-scope="{row}">
          <base-button
            @click.stop="() => add(row)"
            size="small"
            prepend-icon="ri-add-line"
            :label="$t('general.label.add')"
          />
        </template>
      </el-table-column>
    </el-table>
    <add-resource-dialog
      ref="addResourceGroupDialog"
    ></add-resource-dialog>
    <template #footer v-if="totalPages && totalPages > 1">
      <base-pagination
        v-model="page"
        :pages="totalPages"
      />
    </template>
  </page-layout>
</template>

<script>
import { debounce, get, orderBy } from 'lodash'
import TableLoader from '@/mixins/TableLoader'
import CancelRequest from '@/mixins/CancelRequest'
import { mapActions, mapGetters } from 'vuex'
import ViewProduct from '@/mixins/ViewProduct'
import PageLayout from '@/layouts/PageLayout'
import AddResourceDialog from '@/components/Backoffice/Products/AddResourceDialog'
import ProductCompositionResource from '@/app/models/ProductCompositionResource'

export default {
  name: 'ProductAddResource',
  components: {
    PageLayout,
    AddResourceDialog
  },
  mixins: [TableLoader, CancelRequest, ViewProduct],
  data () {
    return {
      loading: false,
      search: '',
      key: null,
      page: 0,
      sort: { order: 'ascending', prop: 'ospId' },
      size: 15
    }
  },
  watch: {
    'pagination.page': debounce(function () {
      this.fetchData()
    }, 200),
    'pagination.sort': debounce(function () {
      this.fetchData()
    }, 200),
    totalPages (v) {
      if (v && this.page >= v) {
        this.page = v - 1
      }
    }
  },
  computed: {
    ...mapGetters({
      user: 'user/user',
      dirtyData: 'product/allResourcegroups'
    }),
    title () {
      return this.$t('products_management.view_product.add_resourcegroup')
    },
    tableData () {
      const prop = this.sort.prop
      const order = this.sort.order === 'descending' ? 'desc' : 'asc'

      return orderBy(this.dirtyData.filter(this.filterData),
        v => {
          try {
            return v[prop].toLowerCase()
          } catch (e) {
            return v[prop]
          }
        },
        order).slice(this.page * this.size, (this.page + 1) * this.size)
    },
    totalPages () {
      try {
        return Math.ceil(this.dirtyData.length / this.size)
      } catch (e) {
        return 1
      }
    }
  },
  methods: {
    ...mapActions({
      getAllResourcegroups: 'product/getAllResourcegroups'
    }),
    filterData (resource) {
      return resource.ospId.toLowerCase().includes(this.search.toLowerCase())
    },
    add ({ ospId }) {
      const resource = new ProductCompositionResource({ ospId })
      this.$refs.addResourceGroupDialog.show({
        resource,
        productOspId: this.productOspId
      })
    },
    fetchData () {
      this.TableLoader_show()
      const filter = {
        active: true,
        resolve: true,
        extId: get(this.$auth.userData(), 'extId', null),
        mrpOspId: {
          ospId: get(this.user, 'aud', null)
        }
      }
      return this.getAllResourcegroups({
        filter,
        options: {
          onFinally: this.TableLoader_hide
        }
      })
    },
    sortChange (sort) {
      this.sort = sort
    }
  },
  mounted () {
    this.fetchData()
  }
}
</script>
