import Model from './Model'
import { DateTime } from 'luxon'
import api from '@/api'
import { DATE_TIME_ISO } from '@/app/helpers'
import { get, toNumber } from 'lodash'

class ProductCompositionResource extends Model {
  // configurationEntry: {currency: "EUR", feeCronNotation: "11",…}
  // currency: "EUR"
  // feeCronNotation: "11"
  // fixedCost: 11
  // fixedCostForUser: true
  // fixedCostLabel: {defaultValue: "11", labelOspId: "OlympusMobility.LABEL.efc05e8c-79c5-4d48-b266-a2c8f026d7c1"}
  // resourceContextOspId: {ospId: "artemis.RESOURCE_GROUP.BIKE_RESOURCES"}
  // resourceOrResourceGroupOspId: {ospId: "artemis.RESOURCE_GROUP.BIKE_RESOURCES"}
  // validFrom: "2021-04-25T21:00:00+0000"
  // validTo: "2021-04-26T21:00:00+0000"
  // variableAmount: 11
  // variableCost: 11
  // variableCostForUser: true
  // variableCostLabel: {defaultValue: "yyu"}
  // variableProRate: "11"
  // productOspId: "OlympusMobility.PRODUCT.test1"
  ospId = null
  currency = 'EUR'
  feeCronNotation = undefined
  fixedCost = undefined
  fixedAmount = undefined
  fixedCostForUser = undefined
  fixedCostLabel = {
    defaultValue: '',
    labelTranslations: []
  }

  validFrom = DateTime.local().toFormat(DATE_TIME_ISO)
  validTo = undefined
  variableAmount = undefined
  variableCost = undefined
  variableCostForUser = undefined
  variableCostLabel = {
    defaultValue: '',
    labelTranslations: []
  }

  variableProRate = undefined
  productOspId = undefined
  vatValue = undefined

  constructor (data = {}) {
    super(data)
    this.initialize(data)
  }

  initialize (object) {
    Object.assign(this, object)
    this.ospId = get(object, 'ospId') || get(object, 'resourceContextOspId.ospId') || get(object, 'resourceOrResourceGroupOspId.ospId')
    this.vatValue = get(object, 'vatValue') || get(object, 'vatPercentage')
    this.fixedCostLabel = get(object, 'fixedCostLabel') || this.getDefaultTranslatableObject()
    this.variableCostLabel = get(object, 'variableCostLabel') || this.getDefaultTranslatableObject()
  }

  static checkIsValid (resource) {
    return !resource.validTo || DateTime.local() < DateTime.fromISO(resource.validTo)
  }

  get isValid () {
    return !this.validTo || DateTime.local() < DateTime.fromISO(this.validTo)
  }

  get configurationEntry () {
    const variableCostLabel = this.variableCostLabel.defaultValue || this.variableCostLabel.labelTranslations.length
      ? this.variableCostLabel : undefined
    const fixedCostLabel = this.fixedCostLabel.defaultValue || this.fixedCostLabel.labelTranslations.length
      ? this.fixedCostLabel : undefined

    const validTo = this.validTo ? DateTime.fromISO(this.validTo).toFormat(DATE_TIME_ISO) : undefined
    const validFrom = this.validFrom ? DateTime.fromISO(this.validFrom).toFormat(DATE_TIME_ISO) : undefined

    let variableCost = this.variableCost
    if (variableCost && variableCost.indexOf('%') > -1) {
      variableCost = toNumber(variableCost.slice(0, variableCost.length - 1))
    }
    // try {
    //   variableCost = variableCost.slice(0, variableCost.length-1)
    // } catch (e) {
    //   variableCost = undefined
    // }

    return {
      ...this,
      variableCostLabel,
      fixedCostLabel,
      validTo,
      validFrom,
      variableCost,
      vatPercentage: this.vatValue,
      resourceContextOspId: this.resourceContextOspId || { ospId: this.ospId },
      resourceOrResourceGroupOspId: this.resourceOrResourceGroupOspId || { ospId: this.ospId }
    }
  }

  addToProduct (productOspId, options = {}) {
    const form = {
      configurationEntry: this.configurationEntry,
      productOspId
    }
    return api.product.addComposition(form, options)
  }

  update (productOspId, options = {}) {
    const form = {
      configurationEntry: this.configurationEntry,
      productOspId
    }
    return api.product.updateComposition(form, options)
  }

  deleteFromProduct (productOspId, options = {}) {
    const form = {
      configurationEntry: {
        currency: this.currency || 'EUR',
        feeCronNotation: this.feeCronNotation,
        resourceContextOspId: this.ospId,
        resourceOrResourceGroupOspId: this.ospId,
        validFrom: this.validFrom,
        validTo: this.validTo,
        vatPercentage: this.vatPercentage
      },
      productOspId
    }
    if (this.variable) {
      form.configurationEntry.variableAmount = this.cost
    } else {
      form.configurationEntry.fixedAmount = this.cost
    }
    return api.product.deleteComposition(form, options)
  }

  getDefaultTranslatableObject () {
    return {
      defaultValue: '',
      labelTranslations: []
    }
  }
}

export default ProductCompositionResource
