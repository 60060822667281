import ProductAddResource from '@/mixins/ProductAddResource'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'ProductViewAddRentalCarResource',
  mixins: [ProductAddResource],
  computed: {
    ...mapGetters({
      dirtyData: 'product/rentalCarResources'
    }),
    title () {
      return this.$t('products_management.view_product.add_rental_car_resource')
    }
  },
  methods: {
    ...mapActions({
      getAllRentalCarResources: 'product/getAllRentalCarResources'
    }),
    fetchData () {
      this.TableLoader_show()
      return this.getAllRentalCarResources({
        onFinally: this.TableLoader_hide
      })
    }
  }
}
